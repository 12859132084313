import { type FC } from 'react';
import { OverlayView } from './Overlay/OverlayView';
import { Spinner } from 'dibs-elements/exports/Spinner';
import styles from './loadingComponent.scss';

const LoadingComponent: FC = () => (
    <OverlayView isTransparent isAboveOverlay isBodyScrollLocked={false}>
        <Spinner dark containerClass={styles.centeredDiv} />
    </OverlayView>
);

export { LoadingComponent };
