import { trackEvent, eventNameConstants, trackUserEventSearch } from 'dibs-tracking';
import { getCategory, getCategories } from '../../categoryHelpers';
import { getFilterValue } from '../../../finding/SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { type Environment } from 'react-relay';

type AppliedFilters = ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
        readonly code: string | null;
    } | null> | null;
} | null> | null;

type AppliedFilter = {
    readonly name: string | null;
    readonly values: ReadonlyArray<{
        readonly code: string | null;
        readonly urlLabel: string | null;
    } | null> | null;
} | null;

const RESULTS_PAGE_USER_TRACKING_CATEGORY = 'results page user tracking';

export function getPersonalizationTrackingData(appliedFilters: AppliedFilters = []): {
    action?: string;
    l1CategoryCode?: string;
} {
    const categories = getCategories(appliedFilters) as AppliedFilter[];
    const l1CategoryCode = (getCategory(categories) as AppliedFilter)?.values?.[0]?.code || '';

    // action will be comma separated values like >
    // 'categoryL1:F, categoryL2:F_TAB, categoryL3:F_TAB_SID, material:6712, origin:3102, period:1012, creator:246'
    const action = (appliedFilters || [])
        .reduce((memo, filter) => {
            const filterName = filter?.name;
            const filterCode = filter?.values?.[0]?.code;

            if (filterCode) {
                memo.push(`${filterName}:${filterCode}`);
            }

            return memo;
        }, [] as string[])
        .join(', ');
    return { action, l1CategoryCode };
}

export function trackPersonalizationEvent({
    action,
    l1CategoryCode,
}: {
    action?: string;
    l1CategoryCode?: string;
}): void {
    trackEvent({
        category: RESULTS_PAGE_USER_TRACKING_CATEGORY,
        action,
        label: l1CategoryCode,
        value: 0,
    });
}

let hasFiredEligibleForRerankEvent = false;

export const getHasFiredEligibleForRerankEvent = (): boolean => hasFiredEligibleForRerankEvent;

export function trackEligibleForRerankEvent(): void {
    trackEvent({
        category: RESULTS_PAGE_USER_TRACKING_CATEGORY,
        action: 'eligible for rerank',
        label: 'none',
    });
    hasFiredEligibleForRerankEvent = true;
}

const trackPersonalizationSearchEvent = (search_term: string): void => {
    trackEvent({
        eventName: eventNameConstants.EVENT_SEARCH,
        search_term,
        category: RESULTS_PAGE_USER_TRACKING_CATEGORY,
        action: 'site search',
        label: search_term,
        noninteraction: 1,
        value: 0,
    });
};

// MW-325: record the category and attribute information of the page and send it to GA in an even
// (this is used for personalization)
export const addPersonalizationTrackingData = ({
    appliedFilters = [],
    environment,
}: {
    appliedFilters: AppliedFilter[];
    environment: Environment;
}): void => {
    trackPersonalizationEvent(getPersonalizationTrackingData(appliedFilters));
    const search_term =
        getFilterValue(appliedFilters, 'search')?.urlLabel ||
        getFilterValue(appliedFilters, 'originalSearch')?.urlLabel;

    if (search_term) {
        trackPersonalizationSearchEvent(search_term);
        trackUserEventSearch(environment, search_term);
    }
};
