import { Component, type ReactNode } from 'react';
import classNames from 'classnames';
import styles from './overlayView.scss';

type Props = {
    hasBorder: boolean;
    isBodyScrollLocked: boolean;
    isTransparent: boolean;
    isAboveOverlay: boolean;
    includePadding: boolean;
    className?: string;
    handleScroll?: () => void;
    children: ReactNode;
};

type State = {
    isBodyScrollLocked: boolean;
    ownsScroll: boolean;
};

class OverlayView extends Component<Props, State> {
    static defaultProps = {
        hasBorder: false,
        includePadding: false,
        isBodyScrollLocked: true,
        handleScroll: (): void => {},
    };

    constructor(props: Props) {
        super(props);

        this.scrollY = 0;
        this.state = {
            isBodyScrollLocked: this.props.isBodyScrollLocked,
            ownsScroll: false,
        };
    }

    static getDerivedStateFromProps(
        nextProps: Props,
        prevState: State
    ): { isBodyScrollLocked: boolean } | null {
        if (nextProps.isBodyScrollLocked !== prevState.isBodyScrollLocked) {
            return {
                isBodyScrollLocked: nextProps.isBodyScrollLocked,
            };
        }

        return null;
    }

    componentDidMount(): void {
        this.toggleBodyScroll(this.isOverlayActive());
    }

    getSnapshotBeforeUpdate(): boolean {
        return this.isOverlayActive();
    }

    componentDidUpdate(prevProps: Props, prevState: State, isOverlayActive: boolean): void {
        if (prevState.isBodyScrollLocked !== this.state.isBodyScrollLocked) {
            this.toggleBodyScroll(isOverlayActive);
        }
    }

    componentWillUnmount(): void {
        this.toggleBodyScroll(this.isOverlayActive());
    }

    scrollY: number;

    saveScrollPosition(): void {
        this.scrollY = window.pageYOffset;
    }

    restoreScrollPosition(): void {
        return window.scrollTo(0, this.scrollY);
    }

    isOverlayActive(): boolean {
        return document.body.classList.contains(styles.noScroll);
    }

    toggleBodyScroll(isOverlayActive: boolean): void {
        const body = document.body;
        const { ownsScroll, isBodyScrollLocked } = this.state;
        if (isBodyScrollLocked && !isOverlayActive) {
            this.saveScrollPosition();
            body.classList.add(styles.noScroll);
            this.setState({
                ownsScroll: true,
            });
        } else if (ownsScroll && isOverlayActive) {
            body.classList.remove(styles.noScroll);
            this.restoreScrollPosition();
            this.setState({
                ownsScroll: false,
            });
        }
    }

    render(): ReactNode {
        const {
            children,
            hasBorder,
            includePadding,
            className,
            isTransparent,
            isAboveOverlay,
            handleScroll,
        } = this.props;
        const overlayClasses = classNames(className, {
            [styles.includePadding]: includePadding,
            [styles.menu]: hasBorder,
            [styles.body]: !hasBorder,
            [styles.transparent]: isTransparent,
            [styles.aboveOverlay]: isAboveOverlay,
        });

        return (
            <div className={overlayClasses} data-tn="modal" onScroll={handleScroll}>
                {children}
            </div>
        );
    }
}

export { OverlayView };
