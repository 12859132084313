import PropTypes from 'prop-types';
import { hydrateRoot } from 'react-dom/client';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import serverVars from 'server-vars';
import { ReactRelayContext } from 'react-relay/legacy';
import { Provider } from 'react-redux';
import { IntlProvider } from 'dibs-react-intl';
import { ServerVarsContextProvider } from '../global/ServerVarsContext/ServerVarsContext';
import { QP_SEARCH_QUERY } from '../constants/queryParamConstants';
import { Error500Layout } from 'dibs-buyer-layout/exports/error500';
import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { SocketContextProvider } from 'dibs-subscriptions-utils/exports/SocketContext';

export function PrepareRelayContextProvider(props) {
    const { environment, query, variables, Component, ...otherProps } = props;
    const operation = createOperationDescriptor(getRequest(query), variables);
    environment.retain(operation);

    const snapshot = environment.lookup(operation.fragment);
    const { data } = snapshot;

    const renderProps = {
        ...variables,
        ...otherProps,
        ...data,
    };

    return (
        <ReactRelayContext.Provider
            value={{
                environment: environment,
                variables: variables || {},
            }}
        >
            <Component {...renderProps} />
        </ReactRelayContext.Provider>
    );
}

PrepareRelayContextProvider.propTypes = {
    environment: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    variables: PropTypes.object.isRequired,
    Component: PropTypes.elementType.isRequired,
};

export function renderEntryComponent(options) {
    const { containerId, store, socketListeners, ...otherProps } = options;
    const searchParams = new URLSearchParams(location.search);

    hydrateRoot(
        document.getElementById(containerId),
        <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
            <ErrorBoundary fallback={({ error }) => <Error500Layout error={error} />}>
                <ServerVarsContextProvider
                    isMobile={serverVars.get('settings.isMobile')}
                    isTablet={serverVars.get('settings.isTablet')}
                    isDesktop={serverVars.get('settings.isDesktop')}
                    isSbAdsEnabled={serverVars.get('isSbAdsEnabled')}
                    clientConf={serverVars.get('config')}
                    localeOrigin={serverVars.get('localeOrigin')}
                    originalUrl={serverVars.get('originalUrl')}
                    isSearchQueryApplied={!!searchParams.get(QP_SEARCH_QUERY)}
                    isSmallerSbCategoryTilesVariant={serverVars.get(
                        'isSmallerSbCategoryTilesVariant'
                    )}
                    isNavigationVariant={serverVars.get('isNavigationVariant')}
                    isTradeQuickFiltersVariant={serverVars.get('isTradeQuickFiltersVariant')}
                    isPdpMwLayoutShiftFixVariant={serverVars.get('isPdpMwLayoutShiftFixVariant')}
                    isShiftBuyRecentSalesVariant={serverVars.get('isShiftBuyRecentSalesVariant')}
                    isReorderedFilterMenuVariant={serverVars.get('isReorderedFilterMenuVariant')}
                    urgencySignalsMwVariant={serverVars.get('urgencySignalsMwVariant')}
                >
                    <SocketContextProvider socketListeners={socketListeners}>
                        <Provider store={store}>
                            <PrepareRelayContextProvider {...otherProps} />
                        </Provider>
                    </SocketContextProvider>
                </ServerVarsContextProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
}
